// App.js
import React from 'react';
import './Slider.css';
import LogoSlider from './LogoSlider';
const Slider = () => {
  const logos = [
    '/images/customers logo/logo01.png',
    '/images/customers logo/logo02.png',
    '/images/customers logo/logo03.png',
    '/images/customers logo/logo04.png',
    '/images/customers logo/logo05.jpg',
    '/images/customers logo/logo01.png',
    '/images/customers logo/logo02.png',
    '/images/customers logo/logo03.png',
    '/images/customers logo/logo04.png',
    '/images/customers logo/logo05.jpg',
  ];

  return (
    <div className="Slider">
      <h1>Our client</h1>
      <LogoSlider logos={logos} />
    </div>
    
  );
};

export default Slider;
