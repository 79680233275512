import React from 'react'
import './Home.css'
// import Sliderhome from './Swiper-Slide-home/slider-home'
import MyCarousel from './Swiper-Slide-home/MyCarousel'
import Slider from '../Swiper-Slide/slider';
import Boxcontentone from '../Content/boxcontentone'
import Boxcontentfour from '../Content/boxcontentfour'
import MyCard from '../Cards/MyCard'
import CustomCards from '../Cards/CustomCards'

function Home() {
  return (
    <>
      <div className='intrall-top '>
        <div className='intrall lefe'>
          <h4>Road Safety Solutions</h4>
          <h1>Quality service are our specialty.</h1>
        </div>
        <div className='intrall right '>
          {/* <h1></h1> */}
          <p>We prioritize confidence by meticulously selecting top-tier ingredients for our services. <br></br>Our commitment to quality ensures that customers experience excellence, <br></br>savoring the finest ingredients that meet our stringent standards.</p>
        </div>
      </div>
      <MyCarousel />
        <Boxcontentone />
      <div className='wrapper container-home'>
        <MyCard />
        <CustomCards />
        <Boxcontentfour />
      </div>
      <Slider />
    </>
  )
}

export default Home;
