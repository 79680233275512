import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import './Slider-home.css'

const MyCarousel = () => {
    return (
        <Carousel className='Slide-one'>
            <Carousel.Item interval={2000}>
                <img
                    className="d-block w-100"
                    src="/images/content image/img49.jpg"
                    alt="First slide"
                    style={{ height: "450px" }}
                />
                <Carousel.Caption  class="carousel-caption d-none d-md-block">
                {/* <h3>Making safety areas in factories for safety</h3> */}
                    <br></br>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
                <img
                    className="d-block w-100"
                    src="/images/content image/img50.jpeg"
                    alt="First slide"
                    style={{ height: "450px" }}
                />
                <Carousel.Caption  class="carousel-caption d-none d-md-block">
                {/* <h3>Create space for people to walk in the factory for safety</h3> */}
                    <br></br>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
                <img
                    className="d-block w-100"
                    src="/images/content image/img51.jpg"
                    alt="First slide"
                    style={{ height: "450px" }}
                />
                <Carousel.Caption  class="carousel-caption d-none d-md-block">
                    {/* <h3>Improving and restoring road paint for safety</h3> */}
                    <br></br>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
                <img
                    className="d-block w-100"
                    src="/images/content image/img52.jpg"
                    alt="First slide"
                    style={{ height: "450px" }}
                />
                <Carousel.Caption  class="carousel-caption d-none d-md-block">
                    {/* <h3>Improving and restoring road paint for safety</h3> */}
                    <br></br>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
                <img
                    className="d-block w-100"
                    src="/images/content image/img21.jpg"
                    alt="First slide"
                    style={{ height: "450px" }}
                />
                <Carousel.Caption  class="carousel-caption d-none d-md-block">
                    {/* <h3>Improving and restoring road paint for safety</h3> */}
                    <br></br>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
};

export default MyCarousel;
