import React, { useState } from 'react';
import './Navbar.css'; // Import your custom styles

const Navbar = () => {
  const iconStyles = {
    '--1': 6,
    '--2': 7,
    '--3': 8,
    '--4': 9,
    '--5': 10,
  };

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
    setSearchOpen(false); // Close search when opening menu
  };

  // const toggleSearch = () => {
  //   setSearchOpen(!isSearchOpen);
  //   setMenuOpen(false); // Close menu when opening search
  // };

  return (
    <div className='navbar'>
      <nav className={`navbar ${isMenuOpen ? 'active' : 'container'}`}>
        <div className="container-contral ">
          <div className="logo">
            <a href="/">
              <img src="/images/logo/logoedite.png" alt="Logo" />
            </a>
          </div>

          <div className={`menu ${isMenuOpen ? 'open' : ''}`}>
            <ul>
              <a href="/" style={{ '--1': iconStyles['--1'] }}>
                Home
              </a>
              <a href="/Solutions" style={{ '--1': iconStyles['--2'] }}>
                Solutions
              </a>
              <a href="/News" style={{ '--1': iconStyles['--3'] }} >
                News
              </a>
              <a href="/About" style={{ '--1': iconStyles['--4'] }}>
                About
              </a>
              <a href="/Contact" style={{ '--1': iconStyles['--5'] }}>
                Contact
              </a>
            </ul>
          </div>
          <div className={`search-container ${isSearchOpen ? 'open active' : ''}`}>
            <div className="search">
              <input
                type="search"
                placeholder="Search Your Design"
                aria-label="Search"
                onChange={() => { }}
              />
            </div>
            <div className="search-success">
              <input
              
                type="search"
                placeholder="Search"
                aria-label="Search Success"
                onChange={() => { }}
              />
            </div>
          </div>
          <div className="menu-icon" onClick={toggleMenu}>
            <i className={`fas ${isMenuOpen ? 'fa-times' : 'fa-bars'}`}></i>
          </div>
        </div>
      </nav >
    </div>
  );
};

export default Navbar;
