import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './footer.css';
import ContactIcons from '../Social-icon/ContactIcons';
function Footer() {
    const form = useRef();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const sendEmail = (e) => {
        e.preventDefault();
        setLoading(true);

        emailjs.sendForm('service_n9u6z11', 'template_rfjtgfy', form.current, 'I2Nixa8c87Z3PuQ3j')
            .then((result) => {
                console.log(result.text);
            })
            .catch((error) => {
                console.log(error.text);
            })
            .finally(() => {
                setLoading(false);
                setFormData({
                    name: '',
                    email: ''
                });
            });
    };
    return (
        <>
            <div className="container-1">
                <a className="navbar-brand-1" href="/"><img src={"/images/logo/logoedite.png"} alt="" /></a>
                <div className="all-contact-footer">
                    <h4>Click Here for Contact</h4>
                    <div className="contact-footer-i"><ContactIcons /></div>
                </div>
                <div className="flex-column">
                    <li className="nav-item mb-2">
                        <a href="/" className="nav-link p-0 text-white ">
                            <i class="fa-solid fa-road"></i> Road repair paint
                        </a>
                    </li>
                    <li className="nav-item mb-2">
                        <a href="/" className="nav-link p-0 text-white ">
                            <i class="fa-solid fa-sign-hanging"></i> Aluminum signs
                        </a>
                    </li>
                    <li className="nav-item mb-2 ">
                        <a href="/" className="nav-link p-0 text-white ">
                            <i class="fa-solid fa-file-signature"></i> <span className='ho-text'>General decorative design</span>
                        </a>
                    </li>
                </div>
                <div className="Subscribe" >
                    <form ref={form} onSubmit={sendEmail}>
                        <h5>Subscribe to our newsletter</h5>
                        <p >Monthly digest of what's new and exciting from us.</p>
                        <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                            <input
                                name="name"
                                id="name"
                                type="text"
                                className="form-control"
                                placeholder="Your Name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                            <input
                                name="email"
                                id=""
                                type="text"
                                className="form-control"
                                placeholder="Email address"
                                value={formData.email}
                                onChange={handleChange}
                            />
                            <button className="btn btn text-white" type="submit" disabled={loading}>
                                {loading ? 'Subscribing...' : 'Subscribe'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Footer;
