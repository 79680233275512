import React from 'react'
import '../Content/firstpage.css';
function Ourteam() {
    return (
        <div >
            <div className="heading">
                <div className="About">
                    <h2>OUR TEAMS</h2>
                    <br></br>
                </div>
            </div>

            <div className="grid-8-img-about">
                <img
                    src="/images/content image/img36.jpg"
                    alt=""
                />
                <div className="grid-8-mini-content">
                    <h2>Dedicated professionals ensuring safety, excellence, and innovation in every project.</h2>
                    <p className="gray-p">Vientiane Traffic Team</p>
                </div>
            </div>

            <div className="grid-8-mini-row">
                <div className="grid-8-mini-col">
                    <div className="grid-8-mini-img">
                        <img
                            src="/images/content image/img37.jpg"
                            alt=""
                        />
                    </div>
                    <h5>Blood donation for society</h5>
                    <p className="gray-p">Vientiane Traffic</p>
                </div>
                <div className="grid-8-mini-col">
                    <div className="grid-8-mini-img">
                        <img
                            src="/images/content image/img39.jpg"
                            alt=""
                        />
                    </div>
                    <h5>Give blood to give life</h5>
                    <p className="gray-p">Vientiane Traffic</p>
                </div>
                <div className="grid-8-mini-col">
                    <div className="grid-8-mini-img">
                        <img
                            src="/images/content image/img38.jpg"
                            alt=""
                        />
                    </div>
                    <h5>Create activities to exercise</h5>
                    <p className="gray-p">Vientiane Traffic</p>
                </div>
                <div className="grid-8-mini-col">
                    <div className="grid-8-mini-img">
                        <img
                            src="/images/content image/img35.jpg"
                            alt=""
                        />
                    </div>
                    <h5>Be ready and happy with the work</h5>
                    <p className="gray-p">Vientiane Traffic</p>
                </div>
            </div>
        </div>
    )
}

export default Ourteam;
