import React from 'react';
import './contact.css';
import Slider from '../Swiper-Slide/slider';
import ContactForm from '../Contact/ContactForm';
import Map from '../Contact/Map';

function Contact() {
  return (
    <>
      <div class="wrapper-news ">
        <div class="grid-container">
          <div class="super-grid-left">
            <div className="grid-box-1 grid-color">
              <div className="heading contact">
                <h4>Contact us</h4>
                <div className="line"></div>
                <p>Vientiane Traffic is more than a business; it's a commitment to safety, innovation, and quality. For inquiries, collaborations, or to explore how we can meet your specific needs, reach out to us at the following contact details :</p>
              </div>

              <div className="grid-1-form">
                <ContactForm />
                <br></br>
                <Map />
              </div>
            </div>
            <div className="grid-box-2 grid-color">
              <div className="heading">
                <h4>Contact information below:</h4>
                <div className="line"></div>
              </div>

              <div className="grid-2-row">
                <div className="grid-2-col">
                  <i className="fas fa-envelope"></i>
                  <h4>Email</h4>
                  <p>khamphone_k@vientianetraffic.com <br />info@vientianetraffic.com</p>
                </div>
                <div className="grid-2-col">
                  <i className="fas fa-phone"></i>
                  <h4>Phone</h4>
                  <p>Tel: +856 20 78 911 599 <br />Tel: +856 20 55 424 774</p>
                </div>

                <div className="grid-2-col">
                  <i className="fas fa-map-marked-alt"></i>
                  <h4>Address</h4>
                  <p>Xingda village, saysettha district , vientiane capital Lao PDR</p>
                </div>
              </div>
            </div>
          </div>
          <div className="super-grid-right">
            <div className="grid-box-9 grid-color">
              <div className="heading">
                <h4>FOLLOW US</h4>
                <div className="line"></div>
              </div>

              <div className="grid-9-row">
                <div className="social-box s-1">
                  <a href="https://www.facebook.com/profile.php?id=100057389562049&mibextid=LQQJ4d" target="_blank" rel="noreferrer">
                    <i className="fab fa-facebook o-1"></i><span>Facebook</span>
                  </a>
                </div>
                <div className="social-box s-2">
                  <a href="https://www.facebook.com/profile.php?id=100057389562049&mibextid=LQQJ4d" target="_blank" rel="noreferrer">
                    <i className="fab fa-twitter o-1"></i><span>Twitter</span>
                  </a>
                </div>
                <div className="social-box  s-3">
                  <a href="https://www.facebook.com/profile.php?id=100057389562049&mibextid=LQQJ4d" target="_blank" rel="noreferrer">
                    <i className="fab fa-youtube o-1"></i><span>Youtube</span>
                  </a>
                </div>
                <div className="social-box s-4">
                  <a href="https://www.facebook.com/profile.php?id=100057389562049&mibextid=LQQJ4d" target="_blank" rel="noreferrer">
                    <i className="fab fa-instagram o-1"></i><span>Instagram</span>
                  </a>
                </div>
              </div>
            </div>

            <div className="grid-box-10 grid-color">
              <div className="heading">
                <h4>SERVICE LATEST PROJECT</h4>
                <div className="line"></div>
              </div>

              <div className="grid-10-img">
                <img src="/images//content image/img01.jpg"
                  alt="" />
              </div>
              <div class="grid-10-item">
                {/* <div className="grid-10-item-img">
                  <img src="/images/img02.jpg"
                    alt="" />
                </div> */}
                <div class="grid-6-item-content">
                  <h5>🚦🚧 National Highway R11 km 0+000-km 59+300 + Non Savan intersection - three Pak Mi intersection 🚗✨</h5>
                  <h6>October 23, 2024</h6>
                </div>
              </div>
              <div class="grid-10-item">
                <div className="grid-10-item-img">
                  <img src="/images//content image/img02.jpg"
                    alt="" />
                </div>
                <div class="grid-6-item-content">
                  <div className="grid-10-item-img">
                    <img src="/images//content image/img04.jpg"
                      alt="" />
                  </div>
                </div>
              </div>
              <div class="grid-10-item">
                <div className="grid-10-item-img">
                  <img src="/images/content image/img04.jpg"
                    alt="" />
                </div>
                <div class="grid-6-item-content">
                  <div className="grid-10-item-img">
                    <img src="/images//content image/img05.jpg"
                      alt="" />
                  </div>
                </div>
              </div>
              
              <div class="grid-10-item">
                <div className="grid-10-item-img">
                  <img src="/images//content image/img06.jpg"
                    alt="" />
                </div>
                <div class="grid-6-item-content">
                  <div className="grid-10-item-img">
                    <img src="/images//content image/img07.jpg"
                      alt="" />
                  </div>
                </div>
              </div>
              <div class="grid-10-item">
                <div className="grid-10-item-img">
                  <img src="/images//content image/img08.jpg"
                    alt="" />
                </div>
                <div class="grid-6-item-content">
                  <div className="grid-10-item-img">
                    <img src="/images//content image/img09.jpg"
                      alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Slider />
    </>
  )
}

export default Contact;