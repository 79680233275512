import React from 'react'
import './Map.css'

function Map() {
    return (
        <>
            <iframe className='map'
                title="Google Maps"
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d460.5748452665841!2d102.67718882192698!3d17.939647262626696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTfCsDU2JzIzLjIiTiAxMDLCsDQwJzM4LjUiRQ!5e1!3m2!1sen!2sla!4v1702641584966!5m2!1sen!2sla"
                width="600"
                height="450"
                style={{ border: '0' }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
        </>
    )
}

export default Map;

