import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import './News.css'
function Slidernewsone() {
    return (
        <>
            <div className="News">
                <div className="head-new">
                    <Carousel className='Slid-news'>
                        <Carousel.Item interval={8000}>
                            <img
                                className="d-block w-100"
                                src="/images/news/image12.jpg"
                                alt="First slide"
                                style={{ height: "500px" }}
                            />
                        </Carousel.Item>
                        <Carousel.Item interval={8000}>
                            <img
                                className="d-block w-100"
                                src="/images/news/image01.jpg"
                                alt="First slide"
                                style={{ height: "500px" }}
                            />
                        </Carousel.Item>
                        <Carousel.Item interval={8000}>
                            <img
                                className="d-block w-100"
                                src="/images/news/image03.jpg"
                                alt="First slide"
                                style={{ height: "500px" }}
                            />
                        </Carousel.Item>
                        <Carousel.Item interval={8000}>
                            <img
                                className="d-block w-100"
                                src="/images/news/image04.jpg"
                                alt="First slide"
                                style={{ height: "500px" }}
                            />
                        </Carousel.Item>
                        <Carousel.Item interval={8000}>
                            <img
                                className="d-block w-100"
                                src="/images/news/image05.jpg"
                                alt="First slide"
                                style={{ height: "500px" }}
                            />
                        </Carousel.Item>
                        <Carousel.Item interval={8000}>
                            <img
                                className="d-block w-100"
                                src="/images/news/image06.jpg"
                                alt="First slide"
                                style={{ height: "500px" }}
                            />
                        </Carousel.Item>
                        <Carousel.Item interval={8000}>
                            <img
                                className="d-block w-100"
                                src="/images/news/image07.jpg"
                                alt="First slide"
                                style={{ height: "500px" }}
                            />
                        </Carousel.Item>
                        <Carousel.Item interval={8000}>
                            <img
                                className="d-block w-100"
                                src="/images/news/image08.jpg"
                                alt="First slide"
                                style={{ height: "500px" }}
                            />
                        </Carousel.Item>
                        <Carousel.Item interval={8000}>
                            <img
                                className="d-block w-100"
                                src="/images/news/image09.jpg"
                                alt="First slide"
                                style={{ height: "500px" }}
                            />
                        </Carousel.Item>
                        <Carousel.Item interval={8000}>
                            <img
                                className="d-block w-100"
                                src="/images/news/image10.jpg"
                                alt="First slide"
                                style={{ height: "500px" }}
                            />
                        </Carousel.Item>
                        <Carousel.Item interval={8000}>
                            <img
                                className="d-block w-100"
                                src="/images/news/image11.jpg"
                                alt="First slide"
                                style={{ height: "500px" }}
                            />
                        </Carousel.Item>
                    </Carousel>
                    <div className="news-titel">

                        <h1>Vientiane Traffic Company
                            <br></br>Strengthens Community
                            <br></br>Bonds with Blood Donation Drive</h1>
                        <h5>On September 14, 2023, Vientiane Traffic Company demonstrated its unwavering commitment to community well-being through a heartfelt blood donation drive. The company, known for its dedication to road safety and innovation, extended its impact beyond the streets by actively participating in a blood donation event organized by the Lao National Red Army Organization.
                            <br></br>
                            <br></br>
                            Employees of Vientiane Traffic eagerly embraced the opportunity to contribute to this noble cause, selflessly donating life-saving units of blood. The event was not merely an act of charity but a testament to the company's ethos of corporate responsibility, emphasizing the broader importance of community welfare.
                            <br></br>
                            <br></br>
                            The blood donation initiative aligns with Vientiane Traffic's mission to make a positive impact on society, showcasing the company's dedication to saving lives beyond its core business activities. This altruistic endeavor not only fosters a sense of unity within the company but also strengthens the bonds between Vientiane Traffic and the local community.
                            <br></br>
                            <br></br>
                            By actively participating in initiatives like the blood donation drive, Vientiane Traffic Company goes beyond road safety, embodying a holistic approach to corporate citizenship. The company continues to set an inspiring example, proving that corporate responsibility is integral to creating a harmonious and supportive community.
                        </h5>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Slidernewsone;
