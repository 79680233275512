import React from 'react';
import './Solution Card.css'

const MyCard = () => {
    return (
        <>
            <div className='Cards-so'>
                <h4> Our Services</h4>
                <div className='services-card'>
                    <div className="card-so m-2 ">
                        <img src="/images/content image/img21.jpg" alt="" />
                        <div className="card-body">
                            <h5 className="card-title">Road paint repair</h5>
                            <p className="card-text">
                            Revitalize roads with expert paint repair for a smooth, safe journey.
                            </p>
                            <a href="/Services" className="btn btn-warning">
                                More info
                            </a>
                        </div>
                    </div>
                    <div className="card-so m-2  ">
                        <img src="https://media.istockphoto.com/id/525568423/photo/london-piccadilly-during-night.jpg?s=612x612&w=0&k=20&c=mBHnao0BnbANC1h2E-rOJ_tSviC7jTw9ir4tfp1V6uI=" alt="" />
                        <div className="card-body">
                            <h5 className="card-title">Advertising media signs</h5>
                            <p className="card-text">
                            Elevate your business visibility with impactful advertising media signs.
                            </p>
                            <a href="/Solutions" className="btn btn-warning">
                                More info
                            </a>
                        </div>
                    </div>
                    <div className="card-so m-2 ">
                        <img src="/images/content image/img23.jpg" alt="" />
                        <div className="card-body">
                            <h5 className="card-title">Aluminum signs </h5>
                            <p className="card-text">
                            Durable aluminum signs: lasting impressions, weather-resistant brilliance for your message.
                            </p>
                            <a href="/Solutions" className="btn btn-warning">
                                More info
                            </a>
                        </div>
                    </div>
                    <div className="card-so m-2 ">
                        <img src="/images/content image/img25.jpg" className="card-img-top" alt="" />
                        <div className="card-body">
                            <h5 className="card-title">Made-to-order signs</h5>
                            <p className="card-text">
                            Crafting bespoke signs tailored to your needs for a standout impact
                            </p>
                            <a href="/Solutions" className="btn btn-warning">
                                More info
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MyCard;
