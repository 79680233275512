import React from 'react'
import './firstpage.css';
function boxcontentfour() {
    return (
        <div >
        <div className=" grid-box-8 grid-color">
            <div className="heading">
                <div className="heading-flex">
                    <h4>Safety signs</h4>
                    <p>View all</p>
                </div>
                <div className="line"></div>
            </div>

            <div className="grid-8-img-box">
                <img
                    src="https://media.istockphoto.com/id/471725223/photo/thank-you-for-driving-carefully-sign.webp?b=1&s=170667a&w=0&k=20&c=9i4Z7u67DWYAr--AXS_iRx--yNGICMO0OHf0n85QkH0="
                    alt=""
                />
                <div className="grid-8-mini-content">
                    <div className="apple">Drive safely</div>
                    <h2>Exceptional durability is guaranteed with our premium aluminum dividers, crafted for lasting quality and reliability in every use</h2>
                    <p className="gray-p">Vientiane Traffic @ 2024</p>
                </div>
            </div>

            <div className="grid-8-mini-row">
                <div className="grid-8-mini-col">
                    <div className="grid-8-mini-img">
                        <img
                            src="https://media.istockphoto.com/id/1455390746/photo/traffic-cones-on-road-with-electronic-arrow-pointing-to-the-right-to-divert-traffic-and-white.webp?b=1&s=170667a&w=0&k=20&c=PaNhvfBzSaLWsBOpzU2CoTWI0Rv5J2Qn1FCblUCVT10="
                            alt=""
                        />
                        <div className="apple">BMW</div>
                    </div>
                    <h5>Exceptional durability is guaranteed with our premium aluminum dividers, crafted for lasting quality and reliability in every use</h5>
                    <p className="gray-p">Vientiane Traffic</p>
                </div>
                <div className="grid-8-mini-col">
                    <div className="grid-8-mini-img">
                        <img
                            src="https://media.istockphoto.com/id/477839825/photo/cold-weather-ahead-road-warning-sign.webp?b=1&s=170667a&w=0&k=20&c=p8_iviwxIKaGNHz5JfGwMd5aUydVhu_U07iCzCi6kCU="
                            alt=""
                        />
                        <div className="apple">BMW</div>
                    </div>
                    <h5>Exceptional durability is guaranteed with our premium aluminum dividers, crafted for lasting quality and reliability in every use</h5>
                    <p className="gray-p">Vientiane Traffic</p>
                </div>
                <div className="grid-8-mini-col">
                    <div className="grid-8-mini-img">
                        <img
                            src="https://media.istockphoto.com/id/155276700/photo/stop-sign-with-traffic-and-cars-at-rush-hour.webp?b=1&s=170667a&w=0&k=20&c=sQZt-J2M4-11JScnGZarC9VtpOkHVZfSZecsU-100Fc="
                            alt=""
                        />
                        <div className="apple">BMW</div>
                    </div>
                    <h5>Exceptional durability is guaranteed with our premium aluminum dividers, crafted for lasting quality and reliability in every use.</h5>
                    <p className="gray-p">Vientiane Traffic</p>
                </div>
                <div className="grid-8-mini-col">
                    <div className="grid-8-mini-img">
                        <img
                            src="https://media.istockphoto.com/id/1409551021/photo/an-asian-parents-and-their-son-enjoy-road-trip-with-camper-van.webp?b=1&s=170667a&w=0&k=20&c=KvVhhH2IVT_UJPrcnUOSdGPUWHWlliQfgqnNYJDMhM4="
                            alt=""
                        />
                        <div className="apple">BMW</div>
                    </div>
                    <h5>Exceptional durability is guaranteed with our premium aluminum dividers, crafted for lasting quality and reliability in every use</h5>
                    <p className="gray-p">Vientiane Traffic</p>
                </div>
            </div>
        </div>
        </div>
    )
}

export default boxcontentfour;
