import React from 'react'
import '../Content/firstpage.css';
import './Service.css'
function Service() {
    return (
        < >
        <div className=" grid-box-8 grid-color-ser">
            <div className="heading">
                <div className="heading-flex-ser">
                    <h3>Safety signs</h3>
                    <p>View all</p>
                </div>
                <div className="line"></div>
            </div>

            <div className="grid-8-img-ser">
                <img
                    src="/images/content image/imge30.png"
                    alt=""
                />
            </div>

            <div className="grid-8-mini-row">
                <div className="grid-8-mini-col">
                    <div className="grid-8-mini-img">
                        <img
                            src="https://www.uniformsafetysigns.com.au/assets/uploads/2021/07/Safety-Product-Signs-1205x1024-Option-2.jpg"
                            alt=""
                        />
                        <div className="apple">Vientiane Traffic</div>
                    </div>
                    <h5>Exceptional durability is guaranteed with our premium aluminum dividers, crafted for lasting quality and reliability in every use</h5>
                    <p className="gray-p">Vientiane Traffic</p>
                </div>
                <div className="grid-8-mini-col">
                    <div className="grid-8-mini-img">
                        <img
                            src="https://jclao.com/wp-content/uploads/2017/10/That-Luang-Festival.jpg"
                            alt=""
                        />
                        <div className="apple">Vientiane Traffic</div>
                    </div>
                    <h5>Exceptional durability is guaranteed with our premium aluminum dividers, crafted for lasting quality and reliability in every use.</h5>
                    <p className="gray-p">Vientiane Traffic</p>
                </div>
                <div className="grid-8-mini-col">
                    <div className="grid-8-mini-img">
                        <img
                            src="https://bingobaker.com/image/1695980/544/1/safety-signs.png"
                            alt=""
                        />
                        <div className="apple">Vientiane Traffic</div>
                    </div>
                    <h5>Exceptional durability is guaranteed with our premium aluminum dividers, crafted for lasting quality and reliability in every use</h5>
                    <p className="gray-p">Vientiane Traffic</p>
                </div>
                <div className="grid-8-mini-col">
                    <div className="grid-8-mini-img">
                        <img
                            src="https://eccil.org/wp-content/uploads/2021/01/victory-gate-patuxai-vientiane-laos-southeast-asia-scaled.jpg"
                            alt=""
                        />
                        <div className="apple">Vientiane Traffic</div>
                    </div>
                    <h5>Exceptional durability is guaranteed with our premium aluminum dividers, crafted for lasting quality and reliability in every use</h5>
                    <p className="gray-p">Vientiane Traffic</p>
                </div>
            </div>
        </div>
        <div className=" grid-box-8 grid-color-ser">
            <div className="heading">
                <div className="heading-flex-ser">
                    <h3>Road paint</h3>
                    <p>View all</p>
                </div>
                <div className="line"></div>
            </div>

            <div className="grid-8-img-ser">
                <img
                    src="/images/content image/imge30.jpeg"
                    alt=""
                />
            </div>

            <div className="grid-8-mini-row">
                <div className="grid-8-mini-col">
                    <div className="grid-8-mini-img">
                        <img
                            src="https://s.alicdn.com/@sc04/kf/H5bf1a54d606844849cc1208413e3e855e.jpg_720x720q50.jpg"
                            alt=""
                        />
                        <div className="apple">Vientiane Traffic</div>
                    </div>
                    <h5>Best-selling quality Cold Road Marking Paint Material Price Road Marking Paint</h5>
                    <p className="gray-p">Vientiane Traffic</p>
                </div>
                <div className="grid-8-mini-col">
                    <div className="grid-8-mini-img">
                        <img
                            src="https://m.road-markingpaints.com/photo/pc30727582-pe_wax_line_coating_hot_melt_thermoplastic_road_marking_paint.jpg"
                            alt=""
                        />
                        <div className="apple">Vientiane Traffic</div>
                    </div>
                    <h5>hot-melt thermoplastic road marking paint, Good-quality hot-melt thermoplastic road marking paint </h5>
                    <p className="gray-p">Vientiane Traffic</p>
                </div>
                <div className="grid-8-mini-col">
                    <div className="grid-8-mini-img">
                        <img
                            src="https://www.gdbllb.com/repository/image/feb4bdf6-40d4-457f-bbab-eab45281160e.jpg"
                            alt=""
                        />
                        <div className="apple">Vientiane Traffic</div>
                    </div>
                    <h5>What's the use of good-priced and quality road marking spray paint?</h5>
                    <p className="gray-p">Vientiane Traffic</p>
                </div>
                <div className="grid-8-mini-col">
                    <div className="grid-8-mini-img">
                        <img
                            src="https://vietnammotorbiketoursclub.com/wp-content/uploads/2023/11/bm-travel-adventure-laos-motorbike-tour-4.png"
                            alt=""
                        />
                        <div className="apple">Vientiane Traffic</div>
                    </div>
                    <h5>Drive safely and care for the environment</h5>
                    <p className="gray-p">Vientiane Traffic</p>
                </div>
            </div>
        </div>
        </>
    )
}

export default Service;
