import React from 'react'
import './firstpage.css'
function boxcontentone() {
    return (
        <div className="first-box">
            <div className="first-box-content">
                <img
                    src="./images/content image/img11.jpg"
                    alt=""
                />
                <div className="first-content">
                    <div className="apple">Traffic light</div>
                    <h3>Improving and repainting the road</h3>
                </div>
            </div>

            <div className="first-box-content">
                <img
                    src="./images/content image/img10.jpg"
                    alt=""
                />
                <div className="first-content">
                    <div className="apple">Traffic light</div>
                    <h4>Traffic light repair work along the road along with installation of complete equipment</h4>
                </div>
            </div>

            <div className="first-box-content">
                <img
                    src="./images/content image/img08.jpg"
                    alt=""
                />
                <div className="first-content">
                    <div className="apple">Safety area</div>
                    <h4>Make safety lines in the workplace for safety</h4>
                </div>
            </div>

            <div className="first-box-content">
                <img
                    src="./images/content image/img09.jpg"
                    alt=""
                />
                <div className="first-content">
                    <div className="apple">ROAD REPAIR PAINT</div>
                    <h4>Modern equipment for road painting</h4>
                </div>
            </div>
        </div>
    )
}

export default boxcontentone;
