import React from 'react'
import './News.css';
import Boxcontentone from '../Content/boxcontentone';
import Slidernewsone from './Slider news one';
import Slidernewstwo from './Slider news two';
import Slidernewthree from './Slider news three';
import MoneyPage from './MoneyPage';
function News() {
  return (
    <>
    <div className='wrapper-news'>
      <div className='intrall'>
        <h4>Road Safety Solutions</h4>
        <h1>Quality service are our specialty.</h1>
      </div>
      <Boxcontentone />
      <br></br>
      <h1>Vientiane Traffic News </h1>
      <Slidernewthree />
      <Slidernewsone />
      <Slidernewstwo />
      <MoneyPage />
      </div>
    </>
  )
}

export default News;

