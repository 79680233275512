import React from 'react';
import './MoneyPage.css';

const MoneyPage = () => {
  return (
    <>
      <div className="money-page">
        <h1>Vientiane Traffic Company Pioneers Road Safety <br></br>Solutions for a Secure Tomorrow</h1>
        <p>
          Vientiane,  - In a groundbreaking move towards fostering safer streets and workplaces, Vientiane Traffic Company emerges as the frontrunner in innovative road safety solutions. With a proven track record in road markings, signage, and workplace safety, clients are invited to partner with us for a transformative journey towards enhanced safety standards.
        </p>
        <h2>Why Choose Vientiane Traffic?</h2>
        <p>Cutting-Edge Technologies: Our state-of-the-art technologies ensure precision and durability in road markings, setting new benchmarks for safety.
          Comprehensive Signage Solutions: From warning signs to safety protection signage, we provide a diverse range catering to all safety needs.
          Tailored Workplace Safety: Elevate workplace safety with our expertise in creating customized safety plans, ensuring a secure environment for all.</p>
        <h2>Client Testimonials:</h2>
        <p>Impressed with the quality of road markings. Vientiane Traffic truly leads in safety solutions!" - Satisfied Client</p>
        <h2>Join the Movement:</h2>
        <p>Make safety a priority with Vientiane Traffic Company. Collaborate with us for solutions that redefine safety standards on the streets and in workplaces. Your safety is our mission!</p>
        <img src="https://www.freepnglogos.com/uploads/road-png/road-frontline-sodera-4.png" alt="" />
      </div>
    </>
  );
};

export default MoneyPage;
