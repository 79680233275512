import React from 'react';
import './ContactIcons.css';

const ContactIcons = () => {
  const iconStyles = {
    '--1': 6,
    '--2': 7,
    '--3': 8,
  };

  return (
    <div className="contact-icons">
      <a href="https://www.facebook.com/profile.php?id=100057389562049&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer" style={{ '--1': iconStyles['--1'] }} className="active">
        <i className="fa-brands fa-facebook"></i>
      </a>
      <a href="https://wa.link/1hha8f" target="_blank" rel="noopener noreferrer" style={{ '--1': iconStyles['--2'] }} className="active">
      <i className="fa-brands fa-square-whatsapp"></i>
      </a>
      <a href="https://www.facebook.com/profile.php?id=100057389562049&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer" style={{ '--1': iconStyles['--3'] }} className="active">
      <i className="fa-brands fa-line"></i>
      </a>
    </div>
  );
};

export default ContactIcons;
