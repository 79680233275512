import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import './News.css'
function Slidernewstwo() {
    return (
        <>
            <div className="News">
                <div className="head-new">
                    <Carousel className='Slid-news'>
                        <Carousel.Item interval={8000}>
                            <img
                                className="d-block w-100"
                                src="/images/news/image14.jpg"
                                alt="First slide"
                                style={{ height: "500px" }}
                            />
                        </Carousel.Item>
                        <Carousel.Item interval={8000}>
                            <img
                                className="d-block w-100"
                                src="/images/news/image15.jpg"
                                alt="First slide"
                                style={{ height: "500px" }}
                            />
                        </Carousel.Item>
                        <Carousel.Item interval={8000}>
                            <img
                                className="d-block w-100"
                                src="/images/news/image16.jpg"
                                alt="First slide"
                                style={{ height: "500px" }}
                            />
                        </Carousel.Item>
                        <Carousel.Item interval={8000}>
                            <img
                                className="d-block w-100"
                                src="/images/news/image17.jpg"
                                alt="First slide"
                                style={{ height: "500px" }}
                            />
                        </Carousel.Item>
                        <Carousel.Item interval={8000}>
                            <img
                                className="d-block w-100"
                                src="/images/news/image18.jpg"
                                alt="First slide"
                                style={{ height: "500px" }}
                            />
                        </Carousel.Item>
                        <Carousel.Item interval={8000}>
                            <img
                                className="d-block w-100"
                                src="/images/news/image19.jpg"
                                alt="First slide"
                                style={{ height: "500px" }}
                            />
                        </Carousel.Item>
                        <Carousel.Item interval={8000}>
                            <img
                                className="d-block w-100"
                                src="/images/news/image20.jpg"
                                alt="First slide"
                                style={{ height: "500px" }}
                            />
                        </Carousel.Item>
                    </Carousel>
                    <div className="news-titel">

                        <h1>Vientiane Traffic Company Welcomes
                            <br></br>Transportation Department and Experts to
                            <br></br>Showcase Road Safety Innovations</h1>
                        <h5>Vientiane, January 12, 2022 - The transportation department overseeing Highway 13 South, accompanied by a cadre of industry experts, visited Vientiane Traffic Company, a renowned leader in road safety solutions. The primary purpose of the visit was to closely examine the company's innovative products and delve into their comprehensive work plans.
                            <br></br>
                            <br></br>
                            As the delegation explored the facilities, Mr. Khamphone Keomanydeth, the visionary behind Vientiane Traffic, provided a detailed presentation on the company's commitment to elevating road safety standards. The showcased products, including advanced road markings, signage, and safety solutions, highlighted Vientiane Traffic's pivotal role in enhancing highway visual communication.
                            <br></br>
                            <br></br>
                            The transportation department officials and experts engaged in fruitful discussions with the company's representatives, exchanging insights on cutting-edge technologies and strategic initiatives. The visit's core objective was to foster collaboration and strengthen the partnership between the public sector and Vientiane Traffic Company, with a shared vision of advancing safety measures along Highway 13 South.
                            <br></br>
                            <br></br>
                            This collaborative effort signifies a proactive approach toward creating safer roadways and improving transportation infrastructure. Vientiane Traffic Company remains dedicated to playing a pivotal role in shaping the future of road safety, with a firm belief that partnerships like these pave the way for innovative solutions and a safer journey for all.
                        </h5>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Slidernewstwo;
