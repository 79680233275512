import React from 'react';
import './Slider.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const LogoSlider = ({ logos, linkTo }) => {
    const settings = {
        infinite: true,
        slidesToShow: 7,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint:1250,
                settings: {
                    slidesToShow: 5,
                },
            },
            {
                breakpoint: 880,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 735,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };

    return (
        <div>
            <Slider {...settings} className="custom-logo-slider">
                {logos.map((logo, index) => (
                    <div key={index} className="logo-slide">
                        <a href={linkTo} target="_blank" rel="noopener noreferrer">
                            <img src={logo} alt={`Logo ${index + 1}`} style={{ textTransform: 'none' }} />
                        </a>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default LogoSlider;