import React from 'react'
import './SearchPage.css'
function SearchPage() {
    return (
        <div className='search-fill'>
            <i class="fa-solid fa-magnifying-glass"></i>
            <h2>No search results found.</h2>
            <p>Try again with a different spelling or combination of words, Full words are often the best searches.</p>
            <div className='cry'>
            <i class="fa-solid fa-face-sad-cry"></i>
            <i class="fa-solid fa-face-sad-cry"></i>
            </div>
        </div>
    )
}

export default SearchPage

