import React from 'react'
import './Solutions.css'
import SolutionCard from './Solution Card'
import OurteamSo  from './OurteamSo'
import Service from './Service'

function Solutions() {
  return (
    <>
      <div className='intrall-top '>
        <div className='intrall lefe'>
          <h4><i class="fa-solid fa-ranking-star"></i> Road Safety Solutions</h4>
          <h1>Quality service are our specialty.</h1>
        </div>
        <div className='intrall right '>
          <h1><i class="fa-solid fa-recycle"></i> Materials</h1>
          <p>We prioritize confidence by meticulously selecting top-tier ingredients for our services. <br></br>Our commitment to quality ensures that customers experience excellence, <br></br>savoring the finest ingredients that meet our stringent standards.</p>
        </div>
      </div>
      <div className='materials intrall'>
        <div className='namber-box'>
          <div className='namber'>
            <h1><i class="fa-solid fa-paint-roller"></i></h1>
            <h4>Quality of Road paint</h4>
          </div>
          <p>
            High-quality road paint is durable, providing excellent visibility and reflectivity. It withstands harsh weather conditions, ensuring long-lasting road markings for enhanced safety and navigation, crucial for efficient traffic management.</p>
        </div>
        <div className='namber-box'>
          <div className='namber'>
            <h1><i class="fa-solid fa-sign-hanging"></i></h1>
            <h4>Aluminum signs</h4>
          </div>
          <p>
            Aluminum signs offer exceptional durability and corrosion resistance, making them ideal for outdoor use. Their lightweight yet sturdy construction ensures longevity, and they maintain visibility with minimal maintenance, contributing to effective communication and wayfinding.</p>
        </div>
        <div className='namber-box'>
          <div className='namber'>
            <h1><i class="fa-solid fa-traffic-light"></i></h1>
            <h4>Traffic right</h4>
          </div>
          <p>
            TAn efficient road traffic department ensures prompt responses, consistent regulation enforcement, prioritizes safety, and employs modern technology for traffic management. Transparent communication and community engagement contribute to its effectiveness.</p>
        </div>
        <div className='namber-box'>
          <div className='namber'>
            <h1><i class="fa-solid fa-people-group"></i></h1>
            <h4>Expert team</h4>
          </div>
          <p>Experienced construction work excels in precision, durability, and timely completion. Skilled professionals and attention to detail ensure high-quality structures, meeting safety standards and client expectations with expertise.</p>
        </div>
      </div>
      <div className='Solution-contrall wrapper'>
        <SolutionCard />
        <Service />
        <OurteamSo />
      </div>
    </>
  )
}

export default Solutions;
