import React from 'react';
import './About.css';
import Ourteam from './Ourteam'

function About() {
  return (
    <>
      <div class="wrapper-news ">
        <div class="grid-container">
          <div class="super-grid-left">
            <div className="grid-box-1 grid-color">
              <div className="About">
                <h4>About us</h4>
                {/* <div className="line"></div> */}
                <div className="grid-box-2 grid-color-about">
                  <div className="grid-2-row-about">
                    <div className="grid-2-col-about">
                      <h3>Welcome to Vientiane Traffic!</h3>
                      <p>Vientiane Traffic, we take pride in being a leading individual enterprise in the heart of Vientiane Capital, Lao People's Democratic Republic. Established by the visionary Mr. Khamphone Keomanydeth, our company has been a trusted name in the industry for over a decade, boasting a rich heritage of 10 years in our country and an expanding presence on the international stage.</p>
                    </div>
                    <div className="grid-2-col ">
                      <img src="/images/content image/Picture19.png" alt="Logo" />
                    </div>
                  </div>
                  <div className="grid-2-row-about">
                    <div className="grid-2-col ">
                      <img src="/images/content image/Picture16.png" alt="Logo" />
                    </div>

                    <div className="grid-2-col-about">
                      <h3>International Reach:</h3>
                      <p>Vientiane Traffic has successfully transcended borders to cater to international markets. Our commitment to excellence and adherence to global standards have positioned us as a reliable partner for those seeking top-notch solutions in road safety, signage, and industrial protection.</p>
                      <br></br>
                      <h3>Quality Assurance:</h3>
                      <p>At the heart of our operations is an unwavering commitment to quality. We employ cutting-edge technologies and adhere to stringent quality control measures to ensure that every project we undertake meets or exceeds industry standards. Our dedication to excellence has been instrumental in forging enduring relationships with clients who value precision, reliability, and innovation.</p>
                    </div>
                  </div>

                  
                  <div className="grid-2-col ">
                      <img src="/images/content image/Picture2.png" alt="Logo" />
                    </div>



                </div>
              </div>
            </div>
          </div>
          <div className="super-grid-right">
            <div className="grid-box-9 grid-color">
              <div className="heading">
                <h4>FOLLOW US</h4>
                <div className="line"></div>
              </div>

              <div className="grid-9-row">
                <div className="social-box s-1">
                  <a href="https://www.facebook.com/profile.php?id=100057389562049&mibextid=LQQJ4d" target="_blank" rel="noreferrer">
                    <i className="fab fa-facebook o-1"></i><span>Facebook</span>
                  </a>
                </div>
                <div className="social-box s-2">
                  <a href="https://www.facebook.com/profile.php?id=100057389562049&mibextid=LQQJ4d" target="_blank" rel="noreferrer">
                    <i className="fab fa-twitter o-1"></i><span>Twitter</span>
                  </a>
                </div>
                <div className="social-box  s-3">
                  <a href="https://www.facebook.com/profile.php?id=100057389562049&mibextid=LQQJ4d" target="_blank" rel="noreferrer">
                    <i className="fab fa-youtube o-1"></i><span>Youtube</span>
                  </a>
                </div>
                <div className="social-box s-4">
                  <a href="https://www.facebook.com/profile.php?id=100057389562049&mibextid=LQQJ4d" target="_blank" rel="noreferrer">
                    <i className="fab fa-instagram o-1"></i><span>Instagram</span>
                  </a>
                </div>
              </div>
            </div>

            <div className="grid-box-10 grid-color-ab">
              <div className="heading-about">
                <h4>Vision</h4>
              </div>

              <div className="grid-10-img">
                <img src="/images//content image/img41.jpg"
                  alt="" />
              </div>
              <div class="grid-10-item">
                <div class="grid-6-item-content">
                  <h5>The cornerstone of road safety empowers us to create environments where every journey is secure. We transform roads into safer pathways through precision road marking, innovative signage, and advanced lighting solutions. Visionary strategies guide us in building a future where safety and efficiency coexist harmoniously on every thoroughfare.</h5>
                  <h6>Vientiane Traffic</h6>
                </div>
              </div>
              <div class="grid-10-item">
                <div className="grid-10-item-img-about">
                  <img src="/images//content image/img25.jpg"
                    alt="" />
                </div>
                <div class="grid-6-item-content">
                  <div className="grid-10-item-img-about">
                    <img src="/images//content image/img24.jpg"
                      alt="" />
                  </div>
                </div>
              </div>
              <div className="heading-about">
                <h3>Create a safe environment at your location.</h3>
                {/* <div className="line"></div> */}
              </div>

              <div className="grid-10-img">
                <img src="/images//content image/img40.jpg"
                  alt="" />
              </div>
              <div class="grid-10-item">
                {/* <div className="grid-10-item-img">
                  <img src="/images/img02.jpg"
                    alt="" />
                </div> */}
                <div class="grid-6-item-content">
                  <h5>Thank you for considering Vientiane Traffic as your trusted partner in road safety, signage, and beyond. We look forward to contributing to the success and safety of your projects!</h5>
                  <h6>Vientiane Traffic</h6>
                </div>
              </div>
              <div class="grid-10-item ">
                <div className="grid-10-item-img-about">
                  <img src="/images//content image/Picture14.png"
                    alt="" />
                </div>
                <div class="grid-6-item-content">
                  <div className="grid-10-item-img-about">
                    <img src="/images//content image/Picture7.png"
                      alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Ourteam />
      </div>
    </>
  )
}

export default About;