import React from "react";
import "../Content/firstpage.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel } from "react-bootstrap";
import "./News.css";
function Slidernewsthree() {
  return (
    <>
      <div className="News">
        <div className="head-new">
          <Carousel className="Slid-news">
            <Carousel.Item interval={8000}>
              <img
                className="d-block w-100"
                src="/images/news/image21.png"
                alt="First slide"
                style={{ height: "500px" }}
              />
            </Carousel.Item>
            <Carousel.Item interval={8000}>
              <img
                className="d-block w-100"
                src="/images/news/image22.png"
                alt="First slide"
                style={{ height: "500px" }}
              />
            </Carousel.Item>
            <Carousel.Item interval={8000}>
              <img
                className="d-block w-100"
                src="/images/news/image23.png"
                alt="First slide"
                style={{ height: "500px" }}
              />
            </Carousel.Item>
            <Carousel.Item interval={8000}>
              <img
                className="d-block w-100"
                src="/images/news/image24.png"
                alt="First slide"
                style={{ height: "500px" }}
              />
            </Carousel.Item>
            <Carousel.Item interval={8000}>
              <img
                className="d-block w-100"
                src="/images/news/image25.png"
                alt="First slide"
                style={{ height: "500px" }}
              />
            </Carousel.Item>
          </Carousel>
          <div className="news-titel">
            <h1>
              Thai-Lao Friendship Bridge Road Repairs Foster Bilateral
              Connectivity and Economic Growth
            </h1>
            <h5>
              The successful repairs to the Thai-Lao Friendship Bridge road on
              April 9, 2024, marked a significant milestone in bilateral
              relations. With meticulous planning and swift execution, the
              upgraded infrastructure not only alleviated traffic congestion but
              also fostered safer travel conditions. The communities on both
              sides of the border rejoiced as travel times shortened,
              facilitating easier access to essential services and promoting
              tourism.
              <br></br>
              <br></br>
              Additionally, the enhanced road network invigorated cross-border
              trade, spurring economic activity and bolstering regional
              development efforts. As a testament to effective cooperation
              between Thailand and Laos, this achievement underscores the
              enduring strength of their friendship and the commitment to mutual
              prosperity through infrastructure advancement. The renewed bridge
              signifies a broader vision of interconnectedness and growth in the
              Southeast Asian region, promising a brighter future for both
              nations.
            </h5>
          </div>
        </div>
      </div>
    </>
  );
}

export default Slidernewsthree;
