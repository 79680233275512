import React from 'react'
import '../Content/firstpage.css';
function CustomCards() {
    return (
        <div className="grid-box-6 grid-color">
            <div className="heading">
                <div className="heading-flex">
                    <h4>Latest projects</h4>
                    <p>View all</p>
                </div>
                <div className="line"></div>
                    <div className="grid-6-img-one">
                        <img
                            src="/images/content image/img44.jpg"
                            alt=""
                        />
                </div>
            </div>

            <div className="grid-6-row">
                <div className="grid-6-col">
                    <div className="grid-6-img">
                        <img
                            src="/images/content image/img42.jpg"
                            alt=""
                        />
                        <div className="apple">Vientiane Traffic</div>
                    </div>
                    <h4> Completion of the construction project of the road on the banks of the River from village KaoLiew to Moon the night</h4>
                </div>
                <div className="grid-6-col">
                    <div className="grid-6-img">
                        <img
                            src="/images/content image/img43.jpg"
                            alt=""
                        />
                        <div className="apple">Vientiane Traffic</div>
                    </div>
                    <h4>🌳 Another region with special cold weather  🚧 Complete installation of guardrail 🦍 Sekaman  3 Darkjeung District, Sekong province 💯</h4>
                </div>
                <div className="grid-6-col">
                    <div className="grid-6-img">
                        <img
                            src="/images/content image/img45.jpg"
                            alt=""
                        />
                        <div className="apple">Vientiane Traffic</div>
                    </div>
                    <h4>🚧 Project to improve the 1st 🇱🇦-🇹🇭 Friendship Bridge International Crossing and 📍 Xiengkhouang Municipality</h4>
                </div>
                <div className="grid-6-col">
                    <div className="grid-6-img">
                        <img
                            src="/images/content image/img46.jpg"
                            alt=""
                        />
                        <div className="apple">Vientiane Traffic</div>
                    </div>
                    <h4>🤝 Enable the official use of 4-point 🚦 for traffic lights or POS</h4>
                </div>
            </div>
        </div>
    )
}

export default CustomCards;
