import './App.css';
import React from 'react';
// import ReactDOM from 'react-dom/client';np
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import Navbar from './Navbar/Navbar';
import Footer from './Footer/footer';
import Home from './Home/Home';
import Solutions from './Solutions/Solutions';
import News from './News/News';
import About from './About/About';
import Contact from './Contact/Contact';
import SearchResults from './SearchPage/SearchPage';

function App() {
  return (
    <Router >
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/Solutions' element={<Solutions />} />
        <Route path='/News' element={<News />} />
        <Route path='/About' element={<About />} />
        <Route path='/Contact' element={<Contact />} />
        <Route path='/search' element={<SearchResults />} />
      </Routes>
      <Footer />
      <div className='Last-footer'>© 2024 Vientiane Traffic, Inc. All rights reserved by Aittisone </div>
    </Router>
  );
}

export default App;
